(function ($) {
    'use strict';

    var elements = document.getElementsByClassName('form-delete');

    for (var i = 0; i < elements.length; i++) {
        elements[i].onclick = function (event) {
            if (confirm('Czy jesteś pewien, że chcesz usunąć element?')) {
                const anchor = event.target.closest('a');
                if (!anchor) return false;
                const url = anchor.getAttribute('href');

                fetch(url, {
                    method: 'DELETE',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => response.json())
                    .then(data => window.location.reload());

                return false;
            }

            return false;
        };
    }
})();
